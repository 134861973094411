import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import Modal from "../../../UiComponents/Modal";
import { PDFViewer } from "@react-pdf/renderer";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import {
  CheckBox,
  DropdownInput,
  TextArea,
  DisabledInput,
  DateInput,
  DropdownWithSearch,
  TextInput,
} from "../../../Inputs";
import PrintFormat from "./PrintFormat";
import axios from "axios";
import { push } from "../../../redux/features/opentabs";

import { useGetProductQuery } from "../../../redux/services/ProductMasterService";

import { useGetUomQuery } from "../../../redux/services/UomMasterService";
import { dropDownListObject } from "../../../Utils/contructObject";
import {
  findFromList,
  getDateFromDateTime,
  getPriceColumnFromPriceRange,
  priceWithTax,
  substract,
} from "../../../Utils/helper";
import {
  useGetPartyByIdQuery,
  useGetPartyQuery,
} from "../../../redux/services/PartyMasterService";
import { Loader } from "../../../Basic/components";
import { useGetStateQuery } from "../../../redux/services/StateMasterService";
import {
  useAddQuotesMutation,
  useDeleteQuotesMutation,
  useGetQuotesByIdQuery,
  useGetQuotesQuery,
  useUpdateQuotesMutation,
} from "../../../redux/services/QuotesService";
import { BELL_ICON, DELETE } from "../../../icons";
import QuotesBillForm from "./QuotesBillForm";
import { salePriceRange } from "../../../Utils/DropdownData";
import QuoteTypeNewOrExistingProject from "../../ReusableComponents/QuoteTypeNewOrExistingProject";
import LeadDropdown from "../../ReusableComponents/LeadDropdown";
import ProjectDropdown from "../../ReusableComponents/ProjectDropdown";
import moment from "moment";
import tw from "../../../Utils/tailwind-react-pdf";
import {
  useGetLeadByIdQuery,
  useGetLeadQuery,
} from "../../../redux/services/LeadFormService";
import Consolidation from "./Consolidation";
import { parse } from "@fortawesome/fontawesome-svg-core";
import PartySearchComponent from "../Leads/PartySearchComponent";
import ShippingAddressComponent from "./ShippingAddressComponenet";
import { useDispatch } from "react-redux";
import { ROLES_API, PAGES_API } from "../../../Api";
import { useGetPageGroupQuery } from "../../../redux/services/PageGroupMasterServices";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useInvalidateTags from "../../../CustomHooks/useInvalidateTags";

const BASE_URL = process.env.REACT_APP_SERVER_URL;
const MODEL = "Quotes";

export default function Form() {
  const openTabs = useSelector((state) => state.openTabs);
  const [leadId, setLeadId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [clientId, setClientId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [form, setForm] = useState(
    openTabs.tabs.find((i) => i.name == "QUOTES")?.projectForm || false
  );



  const defaultTermsAndCondition =
    "50% Payment in advance.25% Payment after 50% work finish.15% Payment after 75% work finish.10% After Finishing Work.Note: For tax bills, an 18% amount is added for untaxed items.Actual quantity/area/weight(Including Wastage) is taken for final bill.Civil & Painting work is in the client's scope.Electrical & Plumbing are in the client scope.Electricity for work will be supplied by the client Ladder, Scaffolding & crane are in the client scope. Make sure the site is suitable for working conditions in the client's scope.Chairs are in the client scope.";

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  );

  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };

  const {
    data: allData,
    isLoading,
    isFetching,
  } = useGetQuotesQuery({ params: { branchId }, searchParams: searchValue });
  const quoteUpdate = openTabs.tabs.find((i) => i.name == "QUOTES")?.projectId;
  const projectForm = openTabs.tabs.find(
    (i) => i.name == "QUOTES"
  )?.projectForm;
  const leadUpdate = openTabs.tabs.find((i) => i.name == "QUOTES")?.quoteId;

  useEffect(() => {
    if (!quoteUpdate && !leadUpdate) return;
    if (quoteUpdate == "new") {
      setId("");
      setLeadId(leadUpdate);
      setForm(projectForm);
    } else {
      setLeadId("");
      setId(quoteUpdate);
      setForm(projectForm);
    }


    dispatch(push({ name: "QUOTES", projectForm: false }));
  }, [quoteUpdate, leadUpdate, projectForm]);

  const [invalidateTagsDispatch] = useInvalidateTags();


  const {
    data: singleLeadData,
    isFetching: isSingleLeadFetching,
    isLoading: isSingleLeadLoading,
  } = useGetLeadByIdQuery(leadId, { skip: !leadId });

  useEffect(() => {
    if (id) return;
    if (!leadId) return;
    setProjectName(singleLeadData?.data?.projectName || "");
    setClientId(singleLeadData?.data?.clientId || "");
    let validDate = new Date();

    validDate.setDate(validDate.getDate() + 15);

    setValidDate(moment(validDate).format("YYYY-MM-DD"));
    setDate(moment.utc(today).format("YYYY-MM-DD"));
    setPlaceOfSupplyId("");
    setQuoteType("New");
    setReadOnly(false);
    setQuotesItems([]);
    setTransportCost("");
    setTransportTax("");
    setTotalDiscount();
    setTermsAndCondition(defaultTermsAndCondition);
    setActive(true);
  }, [
    isSingleLeadFetching,
    singleLeadData,
    isSingleLeadLoading,
    leadId,
    setLeadId,
  ]);

  const today = new Date();
  const [transaction, setTransaction] = useState();
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [active, setActive] = useState(true);
  const [quotesItems, setQuotesItems] = useState([]);
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [gstNo, setGstNo] = useState("");
  const [docId, setDocId] = useState("");
  const [formReport, setFormReport] = useState(false);
  const [priceRange, setPriceRange] = useState("STANDARD");
  const [print, setPrint] = useState(false);
  const [validDate, setValidDate] = useState();
  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [isDifferAddress, setIsDifferAddress] = useState(false);
  const [billingId, setBillingId] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [placeOfSupplyId, setPlaceOfSupplyId] = useState("");
  const [projectId, setProjectId] = useState("");
  const childRecord = useRef(0);
  const [shippingAddressId, setShippingAddressId] = useState("");
  const [addressData, setAddressData] = useState([]);
  const [isTotalDiscount, setIsTotalDiscount] = useState(false);

  const [quoteVersion, setQuoteVersion] = useState("1");
  const [quoteType, setQuoteType] = useState("New");

  const [isNewVersion, setIsNewVersion] = useState(false);
  const [isIgst, setIsIgst] = useState(false);
  const [transportCost, setTransportCost] = useState("");
  const [transportTax, setTransportTax] = useState("");
  const [totalDiscount, setTotalDiscount] = useState("");
  const [navigateProjectId, setNavigateProjectId] = useState();
  const [allowedPages, setAllowedPages] = useState([]);
  const [projectCount, setProjectCount] = useState(0);

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetQuotesByIdQuery(id, { skip: !id });

  const [addData] = useAddQuotesMutation();
  const [updateData] = useUpdateQuotesMutation();
  const [removeData] = useDeleteQuotesMutation();
  const { data: partyList } = useGetPartyQuery({ params });
  const { data: stateList } = useGetStateQuery({ params });
  const { data: productList } = useGetProductQuery({ params });
  const dispatch = useDispatch();
  const {
    data: singlePartyList,
    isSinglePartyFetching: isSinglePartyFetching,
    isSinglePartyLoading: isSinglePartyLoading,
  } = useGetPartyByIdQuery(clientId, { skip: !clientId });
  const { data: uomList } = useGetUomQuery({ params });
  const { data: pageGroup } = useGetPageGroupQuery({});

  // function getNextDocId() {
  //   if (id || isLoading || isFetching) return;
  //   if (allData?.nextDocId) {
  //     setDocId(allData.nextDocId);
  //   }
  // }

  const getNextDocId = useCallback(() => {
    if (id || isLoading || isFetching) return;
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId);
    }
  }, [allData, isLoading, isFetching, id]);

  useEffect(getNextDocId, [getNextDocId]);

  const syncFormWithDb = useCallback(
    (data) => {
      if (!id) return;
      if (leadUpdate) return;

      if (id) setReadOnly(true);
      if (data?.docId) {
        setDocId(data?.docId);
      }
      setActive(id ? (data?.active ? data.active : false) : true);
      setDate(
        data?.createdAt
          ? moment.utc(data.createdAt).format("YYYY-MM-DD")
          : moment.utc(today).format("YYYY-MM-DD")
      );
      setProjectName(data?.projectName ? data?.projectName : "");
      setClientId(data?.clientId ? data?.clientId : "");
      setBillingId(data?.billingId ? data?.billingId : "");
      setBillingAddress(data?.billingAddress ? data?.billingAddress : "");
      setPlaceOfSupplyId(data?.placeOfSupplyId ? data?.placeOfSupplyId : "");
      setValidDate(
        data?.validDate ? moment(data?.validDate).format("YYYY-MM-DD") : ""
      );
      setIsTotalDiscount(
        id ? (data?.isTotalDiscount ? data?.isTotalDiscount : false) : false
      );
      setShippingAddress(data?.shippingAddress ? data?.shippingAddress : "");
      setQuotesItems(data?.QuotesItems ? data?.QuotesItems : []);
      setIsNewVersion(false);
      setQuoteVersion(data?.quoteVersion || 1);
      console.log(data?.QuoteVersion, "quoteversion");
      setPriceRange(
        data?.quoteVersion
          ? data?.QuoteVersion.find(
            (i) => parseInt(i.quoteVersion) === parseInt(data?.quoteVersion)
          )?.priceRange
          : "STANDARD"
      );
      setLeadId(data?.leadId ? data?.leadId : "");
      setQuoteType(data?.projectId ? "Existing" : "New");
      setProjectId(data?.projectId || "");
      setIsIgst(data?.isIgst ? data?.isIgst : false);
      setTransportCost(data?.transportCost ? data?.transportCost : "");
      setTransportTax(data?.transportTax ? data?.transportTax : "");
      setTotalDiscount(data?.totalDiscount ? data?.totalDiscount : "");
      setIsDifferAddress(
        id ? (data?.isDifferAddress ? data?.isDifferAddress : false) : false
      );
      setTermsAndCondition(
        data?.termsAndCondition ? data?.termsAndCondition : ""
      );
      childRecord.current = data?.childRecord ? data?.childRecord : 0;
    },
    [id]
  );
  console.log(shippingAddress? shippingAddress : "" ,"shippingAddress")

  useEffect(() => {
    if (!id) return;
    let data = singleData?.data;
    setPriceRange(
      quoteVersion
        ? data?.QuoteVersion.find(
          (i) => parseInt(i.quoteVersion) === parseInt(quoteVersion)
        )?.priceRange
        : ""
    );
  }, [quoteVersion, singleData]);

  useEffect(() => {
    syncFormWithDb(singleData?.data);
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    validDate,
    branchId,
    clientId,
    priceRange,
    isIgst,
    isDifferAddress,
    billingId,
    isTotalDiscount,
    projectName,
    termsAndCondition,
    placeOfSupplyId,
    billingAddress,
    isNewVersion,
    quotesItems: quotesItems.filter((item) => item?.productId),
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
    active,
    id,
    leadId,
    projectId,
    quoteType,
    quoteVersion,
    shippingAddress,
    totalDiscount,
    transportCost,
    transportTax,
    active: true,
  };

  const validateData = (data) => {
    if (!data.clientId || !data?.placeOfSupplyId || !data?.priceRange || !data?.leadId) {
      return false;
    }

    if (quoteType === "New") {
      if (!data?.leadId) return false;
      else {
        return true;
      }
    }

    if (
      data.quotesItems.filter((i) =>
        data?.isNewVersion
          ? i.quoteVersion === "New"
          : i.quoteVersion === data.quoteVersion || i.quoteVersion === "New"
      ).length === 0
    ) {
      return false;
    }

    return true;
  };


  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      if (returnData.statusCode === 0) {
        setId(returnData?.data?.id);
        toast.success(text + "Successfully");
      } else {
        toast.error(returnData?.message);
      }
      invalidateTagsDispatch()
      dispatch({
        type: `leadForm/invalidateTags`,
        payload: ["Lead"],
      });
    } catch (error) {
      console.log("handle");
    }
  };

  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", {
        position: "top-center",
      });
      return;
    }
    if (!window.confirm("Are you sure save the details ...?")) {
      return;
    }

    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  };

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        let returnData = await removeData(id).unwrap();
        if (returnData.statusCode === 0) {
          setId("");
          syncFormWithDb(undefined);
          toast.success("Deleted Successfully");
        } else {
          toast.error(returnData?.message);
        }
        invalidateTagsDispatch()
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  useEffect(() => {
    if (id) return;
    let termsConditionData = allData?.data?.find(
      (item) => item.termsAndCondition != ""
    )?.termsAndCondition;
    setTermsAndCondition(termsConditionData);
  }, [allData, isFetching, isLoading]);

  useEffect(() => {
    if (id) return;
    setQuotesItems((prev) => {
      if (prev.length >= 5) return prev;
      let newArray = Array.from({ length: 5 - prev.length }, (i) => {
        return {
          productId: "",
          uomId: "",
          qty: "0",
          price: "0.00",
          discount: "0.00",
          amount: "0.000",
          quoteVersion: "New",
          description: "",
          hsnCode: "0",
          taxPercent: "0",
        };
      });
      return [...prev, ...newArray];
    });
  }, [setQuotesItems, id, quotesItems]);

  const onNew = () => {
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId);
    }
    setId("");
    setPriceRange("STANDARD");

    setReadOnly(false);
    setForm(true);
    setSearchValue("");
    setBillingId("");
    setBillingAddress("");
    setClientId("");
    setProjectName("");
    setShippingAddress("");
    setPlaceOfSupplyId("");
    setActive(true);

    setDate(moment.utc(today).format("YYYY-MM-DD"));

    setIsTotalDiscount(false);

    setQuotesItems([]);
    setIsNewVersion(false);
    setQuoteVersion(1);

    setLeadId("");
    setQuoteType("New");
    setProjectId("");
    setIsIgst(false);
    setTransportCost("");
    setTransportTax("");
    setTotalDiscount();
    setIsDifferAddress(false);
    setTermsAndCondition(defaultTermsAndCondition);

    // syncFormWithDb(undefined);
    let validDate = new Date();
    validDate.setDate(validDate.getDate() + 15);
    setValidDate(moment(validDate).format("YYYY-MM-DD"));
  };

  // function handleProjectOpen() {
  //   dispatch(push(
  //     {
  //       active: true,
  //       id: 65,
  //       name: "PROJECT",
  //       projectId: projectCount,
  //       projectForm: true,
  //     }

  //   ));
  // }

  // useEffect(() => {
  //   if (projectCount == "") return
  //   handleProjectOpen();
  // }, [projectCount]);

  const projectOpen = () => {
    let quoteId;
    let projectId;

    if (singleData?.data?.projectId) {
      projectId = singleData?.data?.projectId;
      quoteId = undefined;
    } else {
      quoteId = singleData?.data?.id;
      projectId = "new";
    }

    dispatch(
      push({
        name: "PROJECT",
        projectId: projectId,
        quoteId: quoteId,
        projectForm: true,
      })
    );
  };

  useEffect(() => {
    if (id) return;
    let validDate = new Date();
    validDate.setDate(validDate.getDate() + 15);
    setValidDate(moment(validDate).format("YYYY-MM-DD"));
  }, [setValidDate, id]);

  useEffect(() => {
    let isIgst = singlePartyList?.data?.gstNo
      ? singlePartyList?.data?.gstNo.slice(0, 2)
      : "";
    if (isIgst !== "33" && isIgst !== "") {
      setIsIgst(true);
    } else if (isIgst === "33") {
      setIsIgst(false);
    } else {
      setIsIgst(false);
    }

    setGstNo(singlePartyList?.data?.gstNo ? singlePartyList?.data?.gstNo : "");
  }, [singlePartyList, setGstNo]);

  function addNewRow() {
    setQuotesItems((prev) => [
      ...prev,
      {
        productId: "",
        uomId: "",
        qty: "0",
        price: "0.00",
        discount: "0.00",
        amount: "0.000",
        quoteVersion: "New",
        description: "",
        hsnCode: "0",
        taxPercent: "0",
      },
    ]);
  }

  function deleteRow(index) {
    setQuotesItems((prev) => prev.filter((_, i) => i !== index));
  }

  function handleInputChange(value, index, field) {
    const newBlend = structuredClone(quotesItems);
    const currentProductData = productList?.data
      ? productList?.data.find((item) => parseInt(item.id) === parseInt(value))
      : [];
    if (field == "productId") {
      newBlend[index][field] = value;
      newBlend[index]["price"] = currentProductData?.price || 0;
      newBlend[index]["description"] = currentProductData?.description || "";
      newBlend[index]["hsnCode"] = currentProductData?.hsnCode || "";
      newBlend[index]["uomId"] = currentProductData?.uomId || 0;
      newBlend[index]["taxPercent"] = currentProductData?.taxPercent || "";
    }

    newBlend[index][field] = value;




    setQuotesItems(newBlend);
  }

  useEffect(() => {
    if (!isNewVersion) return;
    setQuotesItems((prev) => {
      let newPrev = structuredClone(prev);
      return [
        ...newPrev.filter((i) => i.quoteVersion !== "New"),
        ...newPrev
          .filter((i) => parseInt(i.quoteVersion) === parseInt(quoteVersion))
          .map((i) => ({ ...i, quoteVersion: "New" })),
      ];
    });
  }, [isNewVersion, quoteVersion]);

  useEffect(() => {
    const products = productList?.data || [];
    setQuotesItems((prev) => {
      if (id) {
        return prev.map((i) => {
          if (i.quoteVersion === "New") {
            let product = products.find(
              (p) => parseInt(p.id) === parseInt(i.productId)
            );
            let productPurchasePrice = product?.price || 0;
            let pricePercent =
              product?.[getPriceColumnFromPriceRange(priceRange)] || 0;
            const productPrice = priceWithTax(
              pricePercent,
              productPurchasePrice
            );
            return { ...i, price: productPrice };
          }
          return i;
        });
      } else {
        return prev.map((i) => {
          let product = products.find(
            (p) => parseInt(p.id) === parseInt(i.productId)
          );
          let productPurchasePrice = product?.price || 0;
          let pricePercent =
            product?.[getPriceColumnFromPriceRange(priceRange)] || 0;
          const productPrice = priceWithTax(pricePercent, productPurchasePrice);
          return { ...i, price: productPrice };
        });
      }
    });
  }, [quoteVersion, productList, priceRange, id]);

  useEffect(() => {
    if (!clientId) return;
    if (isDifferAddress) {
      let Data = partyList?.data?.find(
        (val) => parseInt(val.id) === parseInt(billingId)
      )?.ShippingAddress;
      setAddressData(Data);
    } else {
      let Data = partyList?.data?.find(
        (val) => parseInt(val.id) === parseInt(clientId)
      )?.ShippingAddress;
      setAddressData(Data);
    }
  }, [clientId, partyList, setAddressData, billingId, isDifferAddress]);

  useEffect(() => {
    if (!clientId) return;
    if (id) return;
    let address = singleData?.data
      ? singleData?.data.billingAddress
      : partyList?.data?.find(
        (item) => parseInt(item.id) === parseInt(clientId)
      )?.address;
    let shippingAdd = singleData?.data
      ? singleData?.data.shippingAddressId
      : partyList?.data?.find(
        (item) => parseInt(item.id) === parseInt(clientId)
      )?.address || "";
    setBillingAddress(address);
    if (isDifferAddress && !id) {
      setBillingId();
      setShippingAddress("");
    } else {
      setBillingId(clientId);
      setShippingAddress(shippingAdd);
    }
  }, [
    partyList,
    singleData,
    isDifferAddress,
    setBillingId,
    setShippingAddress,
    setBillingAddress,
    clientId,
  ]);

  useEffect(() => {
    if (!billingId) return;
    let shippingAdd = singleData?.data
      ? singleData?.data.shippingAddress
      : partyList?.data?.find(
        (item) => parseInt(item.id) === parseInt(billingId)
      )?.ShippingAddress[0]?.address || [];
    if (isDifferAddress) {
      setShippingAddress(shippingAdd);
    }
    return;
  }, [billingId]);

  useEffect(() => {
    let PercentageAmount;
    let findTotalPrice;
    let totDis = totalDiscount?.includes("%");
    if (id) {
      findTotalPrice = quotesItems
        ?.filter((item) =>
          isNewVersion
            ? item.quoteVersion === "New"
            : item.quoteVersion == quoteVersion
        )
        ?.reduce(
          (a, b) =>
            a +
            parseFloat(b.qty) * parseFloat(b.price) +
            parseFloat(b.qty) * parseFloat(b.price) * (calGst(b.id) / 100),
          0
        );
    } else {
      findTotalPrice = quotesItems
        ?.filter((item) => item.quoteVersion === "New")
        ?.reduce(
          (a, b) =>
            a +
            parseFloat(b.qty) * parseFloat(b.price) +
            parseFloat(b.qty) *
            parseFloat(b.price) *
            ((b.taxPercent ? b.taxPercent.replace("%", "") : 0) / 100),
          0
        );
    }
    if (totDis) {
      PercentageAmount = totalDiscount?.replace("%", "");
    }

    if (totalDiscount && !totDis) {
      PercentageAmount = findPercent(findTotalPrice, totalDiscount);
      PercentageAmount = parseFloat(PercentageAmount);
    }

    setQuotesItems((prev) => {
      let newObj = structuredClone(prev);

      if (id) {
        newObj = newObj?.filter((item) => isNewVersion ? item.quoteVersion === "New" : item.quoteVersion == quoteVersion);
        let oldObj = prev?.filter((item) => isNewVersion ? item.quoteVersion !== "New" : item.quoteVersion != quoteVersion);
        for (let index = 0; index < newObj.length; index++) {
          let item = newObj[index];
          let amount =
            parseFloat(item["qty"]) * parseFloat(item["price"]) +
            parseFloat(item["qty"]) *
            parseFloat(item["price"]) *
            (calGst(item["id"]) / 100);
          newObj[index]["discount"] = parseFloat(
            amount * ((PercentageAmount || 0) / 100)
          ).toFixed(2);
        }
        return [...newObj, ...oldObj];
      } else if (!id) {
        let array = newObj?.filter((item) => item.quoteVersion === "New");

        for (let index = 0; index < array.length; index++) {
          let item = array[index];
          let amount =
            parseFloat(item["qty"]) * parseFloat(item["price"]) +
            parseFloat(item["qty"]) *
            parseFloat(item["price"]) *
            (calcGst(index, array) / 100);

          array[index]["discount"] = parseFloat(
            amount * ((PercentageAmount || 0) / 100)
          ).toFixed(2);
        }
        return (newObj = array);
      }
    });
  }, [setTotalDiscount, totalDiscount]);


  console.log(quotesItems, "quotesitemssss")

  function onDataClick(id) {
    // setId(id);
    onNew();
    setForm(true);
  }

  function findPercent(totalAmount, totalDiscount) {
    let Percentage = (totalDiscount / totalAmount) * 100;
    Percentage = parseFloat(Percentage);
    return parseFloat(Percentage);
  }

  const calculateGst = (index) => {
    return quotesItems[index]["taxPercent"]
      ? quotesItems[index]["taxPercent"]?.replace("%", "")
      : 0;
  };

  const calGst = (id) => {
    let taxPercent = quotesItems?.find(
      (val) => parseInt(val.id) === parseInt(id)
    )?.taxPercent;
    return taxPercent ? taxPercent.replace("%", "") : 0;
  };
  const calcGst = (index, array) => {
    return array[index]["taxPercent"]
      ? array[index]["taxPercent"]?.replace("%", "")
      : 0;
  };

  function findTotalAmount() {
    let totalCost;
    let transportTaxValue;
    if (id) {
      totalCost = quotesItems?.filter((item) =>
        isNewVersion
          ? item.quoteVersion === "New"
          : item.quoteVersion == quoteVersion
      );
    } else {
      totalCost = quotesItems?.filter(
        (item) => item.quoteVersion === "New" && item?.qty && item?.price
      );
    }

    totalCost = totalCost?.reduce(
      (a, b, index) =>
        a +
        (substract(
          parseFloat(b.qty) * parseFloat(b.price),
          parseFloat(b?.discount || 0)
        ) +
          parseFloat(b.qty) *
          parseFloat(b.price) *
          ((b.taxPercent ? b.taxPercent.replace("%", "") : 0) / 100)),
      0
    );

    if (transportTax?.includes("%")) {
      transportTaxValue = transportCost * (transportTax.replace("%", "") / 100);
    } else {
      transportTaxValue = transportCost * (transportTax / 100);
    }

    totalCost =
      totalCost +
      parseInt(transportCost || 0) +
      parseInt(transportTaxValue || 0);

    return totalCost;
  }

  const isLoadingIndicator = !productList || !partyList || !uomList;

  if (!form)
    return (
      <QuotesBillForm
        onClick={(id) => {
          setId(id);
          setForm(true);
        }}
        onNew={onNew}
        onNewButton={true}
      />
    );

  let count = 1;
console.log(singleData,"singleData")

  return (
    <>

      <div
        onKeyDown={handleKeyDown}
        className="md:items-start md:justify-items-center grid h-screen bg-theme"
      >
        <Modal
          isOpen={formReport}
          onClose={() => setFormReport(false)}
          widthClass={"px-2 h-[100%] w-[90%]"}
        >
          <QuotesBillForm
            onClick={(id) => {
              setId(id);
              setFormReport(false);
            }}
            onNewButton={false}
          />
        </Modal>
        <Modal
          isOpen={print}
          onClose={() => {
            setPrint(false);
          }}
          widthClass={"w-[100%] h-[90%]"}
        >
          <PDFViewer style={tw("w-full h-full")}>
            <PrintFormat
              data={singleData?.data}
              isIgst={singlePartyList?.data?.isIgst}
              party={singlePartyList?.data}
              stateList={stateList || []}
              quoteVersion={quoteVersion}
              shippingAddress = {shippingAddress}
            />
          </PDFViewer>
        </Modal>
        <div className="flex flex-col frame w-full h-full">
          <FormHeader
            setNavigateProjectId={setNavigateProjectId}
            quotesData={singleData?.data}
            projectOpen={projectOpen}
            onNew={onNew}
            model={MODEL}
            onClose={() => {
              setForm(false);
              setSearchValue("");
            }}
            onClick={onDataClick}
            openReport={() => setFormReport(true)}
            saveData={saveData}
            setReadOnly={setReadOnly}
            deleteData={deleteData}
            onPrint={id ? () => setPrint(true) : null}
          />
          <div className="flex-1 grid grid-cols-1 md:grid-cols-4 p-5 gap-x-2 overflow-clip">
            <div className="col-span-4 grid md:grid-cols-1 border overflow-auto">
              <div className="mr-1 md:ml-2">
                <fieldset className="frame border rounded-lg">
                  <legend className="sub-heading font-semibold text-lg">
                    Product Info
                  </legend>
                  <div className="grid grid-cols-7 gap-2 p-4 bg-gray-200 rounded-lg">
                    <div className="col-span-1">
                      <DisabledInput
                        name="Quote No."
                        value={docId}
                        required={true}
                        readOnly={readOnly}
                        className="w-full"
                      />
                    </div>
                    <div className="col-span-1">
                      <DisabledInput
                        name="Quote Date"
                        value={date}
                        type="date"
                        required={true}
                        readOnly={readOnly}
                        className="w-full"
                      />
                    </div>
                    <div className="col-span-1">
                      <PartySearchComponent
                        setPartyId={setClientId}
                        partyId={clientId}
                        id={id}
                        name="Client Name"
                        className="w-full"
                      />
                    </div>
                    <div className="col-span-1">
                      <TextInput
                        required={true}
                        name="Project Name"
                        value={projectName}
                        setValue={setProjectName}
                        className="w-full"
                        readOnly={readOnly}
                      />
                    </div>
                    <div className="col-span-1">
                      <DisabledInput
                        name="GST No."
                        value={gstNo}
                        required={true}
                        readOnly={false}
                        className="w-full"
                      />
                    </div>
                    <div className="col-span-1">
                      <QuoteTypeNewOrExistingProject
                        value={quoteType}
                        setValue={setQuoteType}
                        readOnly={readOnly}
                        className="w-full"
                      />
                    </div>
                    {quoteType === "New" ? (
                      <div className="col-span-1">
                        {readOnly ? (
                          <DisabledInput
                            name="Leads"
                            value={
                              singleData?.data
                                ? singleData?.data?.LeadForm?.docId ? singleData?.data?.LeadForm?.docId : ""
                                : ""
                            }
                            required={true}
                            readOnly={false}
                            className="w-full"
                          />
                        ) : (
                          <LeadDropdown
                            clientId={clientId}
                            readOnly={readOnly}
                            status="Leads"
                            multiSelect={false}
                            withoutLabel={false}
                            name="Lead"
                            selected={leadId}
                            setSelected={setLeadId}
                            id={id}
                            singleData={singleData}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="col-span-1">
                          {readOnly ? (
                            <DisabledInput
                              name="Leads"
                              value={
                                singleData?.data
                                  ? singleData?.data?.LeadForm?.docId
                                  : ""
                              }
                              required={true}
                              readOnly={false}
                              className="w-full"
                            />
                          ) : (
                            <LeadDropdown
                              clientId={clientId}
                              readOnly={readOnly}
                              status="Leads"
                              multiSelect={false}
                              withoutLabel={false}
                              name="Lead"
                              selected={leadId}
                              setSelected={setLeadId}
                              id={id}
                              singleData={singleData}
                            />
                          )}
                        </div>
                        <div className="col-span-1">
                          <ProjectDropdown
                            readOnly={readOnly}
                            clientId={clientId}
                            multiSelect={false}
                            withoutLabel={false}
                            name="Project"
                            selected={projectId}
                            setSelected={setProjectId}
                          />
                        </div>
                      </>
                    )}

                    <div className="col-span-1">
                      <DateInput
                        name="Valid Until"
                        value={validDate}
                        setValue={setValidDate}
                        readOnly={readOnly}
                        className="w-full"
                      />
                    </div>
                    <div className="col-span-1">
                      <DropdownInput
                        name="Place Of Supply"
                        options={dropDownListObject(
                          id
                            ? stateList?.data
                            : stateList?.data?.filter((item) => item.active),
                          "name",
                          "id"
                        )}
                        value={placeOfSupplyId}
                        setValue={setPlaceOfSupplyId}
                        required={true}
                        readOnly={readOnly}
                        className="w-full text-sm"
                      />
                    </div>
                    <div className="col-span-1">
                      <DropdownInput
                        name="Price Range"
                        options={salePriceRange}
                        value={priceRange}
                        setValue={setPriceRange}
                        required={true}
                        readOnly={id ? !isNewVersion : readOnly}
                        disabled={childRecord.current > 0}
                        className="w-full"
                      />
                    </div>
                    {id && (
                      <div className="col-span-1">
                        <DropdownInput
                          readOnly={readOnly}
                          name="Current Version"
                          value={quoteVersion}
                          setValue={(value) => setQuoteVersion(value)}
                          clear={false}
                          options={[
                            ...new Set(
                              quotesItems
                                .filter((i) => i?.quoteVersion !== "New")
                                .map((i) => i.quoteVersion)
                            ),
                          ].map((i) => ({ show: i, value: i }))}
                          className="w-full"
                        />
                      </div>
                    )}

                    {isDifferAddress && (
                      <div className="col-span-1">
                        <PartySearchComponent
                          setPartyId={setBillingId}
                          partyId={billingId}
                          name="Shipping Cl. Name"
                          className="w-full"
                        />
                      </div>
                    )}
                    <div className="col-span-2">
                      <ShippingAddressComponent
                        shippingAddress={shippingAddress}
                        setShippingAddress={setShippingAddress}
                        name="Shipping Address"
                        clientId={clientId}
                        isDifferAddress={isDifferAddress}
                        billingId={billingId}
                        className="w-full"
                      />
                    </div>

                    <div className="col-span-2">
                      <TextArea
                        autoFocus
                        name="Billing Address"
                        value={billingAddress}
                        setValue={setBillingAddress}
                        className="w-full text-sm"
                      />
                    </div>

                    {!readOnly && (
                      <div className="col-span-1">
                        <CheckBox
                          name="New Version"
                          value={isNewVersion}
                          setValue={setIsNewVersion}
                          readOnly={readOnly}
                          className="w-full"
                        />
                      </div>
                    )}
                    <div className="col-span-1">
                      <CheckBox
                        name="Is Total Discount"
                        value={isTotalDiscount}
                        setValue={setIsTotalDiscount}
                        // readOnly={id ? !isNewVersion : readOnly}
                        readOnly={readOnly}
                        className="w-full"
                      />
                    </div>
                    <div className="col-span-1">
                      <CheckBox
                        name="Billing & Shipping Are Differ"
                        value={isDifferAddress}
                        setValue={setIsDifferAddress}
                        readOnly={readOnly}
                        className="w-full"
                      />
                    </div>
                  </div>
                </fieldset>

                <fieldset className="frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 w-full border border-gray-400 md:pb-5 flex flex-1 overflow-auto h-full">
                  <legend className="sub-heading">Quote Details</legend>
                  <div
                    className={` relative w-full overflow-y-auto py-1 h-full`}
                  >
                    <table className="border border-gray-500 text-xs w-full">
                      <thead className="bg-gray-700 text-white border-b uppercase tracking-widest border-gray-500">
                        <tr>
                          <th className="border border-gray-500 w-12 text-center p-2">
                            S.no
                          </th>
                          <th className="border border-gray-500 p-2">
                            Product Name<span className="text-red-500">*</span>
                          </th>
                          <th className="border border-gray-500 p-2">
                            Description
                          </th>
                          <th className="border border-gray-500 p-2">Hsn</th>
                          <th className="border border-gray-500 w-16 p-2">
                            Uom
                          </th>
                          <th className="border border-gray-500 w-16 p-2">
                            Qty
                          </th>
                          <th className="border border-gray-500 w-20 p-2">
                            Price
                          </th>
                          <th className="border border-gray-500 w-16 p-2">
                            Taxable Amount
                          </th>
                          <th className="border border-gray-500 w-16 p-2">
                            Tax Percent %
                          </th>

                          {isIgst ? (
                            <th className="border border-gray-500 w-20 p-2">
                              IGST
                            </th>
                          ) : (
                            <>
                              <th className="border border-gray-500 w-20 p-2">
                                CGST
                              </th>
                              <th className="border border-gray-500 w-20 p-2">
                                SGST
                              </th>
                            </>
                          )}
                          <th className="border border-gray-500 w-20 p-2">
                            Discount
                          </th>
                          <th className="border border-gray-500 w-20 p-2">
                            Amount
                          </th>

                          {!(id ? !isNewVersion : readOnly) && (
                            <th
                              className="border border-gray-500 w-16 p-2"
                              onClick={addNewRow}
                            >
                              <span className="text-2xl">+</span>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-amber-100">
                        {(quotesItems || []).map((item, index) =>
                          (
                            id
                              ? isNewVersion
                                ? item.quoteVersion === "New"
                                : parseInt(item.quoteVersion) ===
                                parseInt(quoteVersion)
                              : true
                          ) ? (
                            <tr key={index} className="w-full">
                              <td className="border border-gray-500 w-7 text-left px-2 py-1">
                                {count++}
                              </td>
                              <td className="border border-gray-500 w-32 px-2 py-1">
                                <DropdownWithSearch
                                  value={item.productId}
                                  readOnly={id ? !isNewVersion : readOnly}
                                  setValue={(value) =>
                                    handleInputChange(value, index, "productId")
                                  }
                                  options={productList?.data?.filter(
                                    (item) => item?.active
                                  )}
                                />
                              </td>
                              <td className="border border-gray-500 w-48 px-2 py-1">
                                <textarea
                                  className="w-full h-24 p-2 border border-gray-500 rounded focus:outline-none"
                                  value={item.description}
                                  disabled={id ? !isNewVersion : readOnly}
                                  onFocus={(e) => e.target.select()}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      index,
                                      "description"
                                    )
                                  }
                                />
                              </td>
                              <td className="border border-gray-500 w-16 px-2 py-1">
                                <input
                                  type="number"
                                  className="w-full p-1 border border-gray-400 rounded text-right"
                                  value={
                                    item?.hsnCode === "0" ? "" : item?.hsnCode
                                  }
                                  disabled={id ? !isNewVersion : readOnly}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      index,
                                      "hsnCode"
                                    )
                                  }
                                  onBlur={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      index,
                                      "hsnCode"
                                    )
                                  }
                                />
                              </td>
                              <td className="border border-gray-500 w-16 px-2 py-1">
                                <select
                                  disabled={id ? !isNewVersion : readOnly}
                                  className="w-full p-1 border border-gray-400 rounded"
                                  value={item.uomId}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      index,
                                      "uomId"
                                    )
                                  }
                                >
                                  <option className="text-gray-600"></option>
                                  {(uomList?.data ? uomList?.data : []).map(
                                    (uom) => (
                                      <option value={uom.id} key={uom.id}>
                                        {uom.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </td>
                              <td className="border border-gray-500 px-2 py-1">
                                <input
                                  type="number"
                                  className="w-full p-1 border border-gray-400 rounded text-right"
                                  value={item.qty === 0 ? "" : item.qty}
                                  disabled={id ? !isNewVersion : readOnly}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      index,
                                      "qty"
                                    )
                                  }
                                />
                              </td>
                              <td className="border border-gray-500 px-2 py-1">
                                <input
                                  type="number"
                                  className="w-full p-1 border border-gray-400 rounded text-right"
                                  value={item?.price === 0 ? "" : item?.price}
                                  disabled={id ? !isNewVersion : readOnly}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      index,
                                      "price"
                                    )
                                  }
                                  onBlur={(e) =>
                                    handleInputChange(
                                      parseFloat(e.target.value).toFixed(2),
                                      index,
                                      "price"
                                    )
                                  }
                                />
                              </td>
                              <td className="border border-gray-500 px-2 py-1">
                                <input
                                  type="number"
                                  className="w-full p-1 border border-gray-400 rounded text-right"
                                  value={
                                    !item.qty || !item.price
                                      ? 0
                                      : parseFloat(
                                        parseFloat(item.qty) *
                                        parseFloat(item.price)
                                      ).toFixed(2) || 0
                                  }
                                  disabled
                                />
                              </td>
                              <td className="border border-gray-500 px-2 py-1">
                                <input
                                  type="number"
                                  disabled={id ? !isNewVersion : readOnly}
                                  className="w-full p-1 border border-gray-400 rounded text-right"
                                  value={
                                    item.taxPercent
                                      ? item.taxPercent.replace("%", "")
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      index,
                                      "taxPercent"
                                    )
                                  }
                                  onBlur={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      index,
                                      "taxPercent"
                                    )
                                  }
                                />
                              </td>
                              {isIgst ? (
                                <td className="border border-gray-500 px-2 py-1">
                                  {!item.qty || !item.price
                                    ? 0
                                    : parseFloat(
                                      parseFloat(item.qty) *
                                      parseFloat(item.price) *
                                      (calculateGst(index) / 100)
                                    ).toFixed(2) || 0}
                                </td>
                              ) : (
                                <>
                                  <td className="border border-gray-500 px-2 py-1">
                                    {!item.qty || !item.price
                                      ? 0
                                      : parseFloat(
                                        parseFloat(item.qty) *
                                        parseFloat(item.price) *
                                        (calculateGst(index) / 2 / 100)
                                      ).toFixed(2) || 0}
                                  </td>
                                  <td className="border border-gray-500 px-2 py-1">
                                    {!item.qty || !item.price
                                      ? 0
                                      : parseFloat(
                                        parseFloat(item.qty) *
                                        parseFloat(item.price) *
                                        (calculateGst(index) / 2 / 100)
                                      ).toFixed(2) || 0}
                                  </td>
                                </>
                              )}
                              <td className="border border-gray-500 px-2 py-1">
                                <input
                                  type="number"
                                  className="w-full p-1 border border-gray-400 rounded text-right"
                                  value={
                                    item.discount === 0 ? "" : item.discount
                                  }
                                  disabled={
                                    id
                                      ? isNewVersion && !isTotalDiscount
                                        ? false
                                        : true
                                      : !readOnly && !isTotalDiscount
                                        ? false
                                        : true
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      index,
                                      "discount"
                                    )
                                  }
                                  onBlur={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      index,
                                      "discount"
                                    )
                                  }
                                />
                              </td>
                              <td className="border border-gray-500 px-2 py-1">
                                <input
                                  type="number"
                                  className="w-full p-1 border border-gray-400 rounded text-right"
                                  value={
                                    !item.qty || !item.price
                                      ? 0
                                      : parseFloat(
                                        substract(
                                          parseFloat(item.qty) *
                                          parseFloat(item.price),
                                          parseFloat(item?.discount || 0)
                                        ) +
                                        parseFloat(item.qty) *
                                        parseFloat(item.price) *
                                        (calculateGst(index) / 100)
                                      ).toFixed(2) || 0
                                  }
                                  disabled
                                />
                              </td>
                              {!(id ? !isNewVersion : readOnly) && (
                                <td className="border border-gray-500 px-2 py-1 text-center">
                                  <button
                                    type="button"
                                    onClick={() => deleteRow(index)}
                                    className="text-red-600"
                                  >
                                    {DELETE}
                                  </button>
                                </td>
                              )}
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </table>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <Consolidation
          // readOnly={id ? !isNewVersion : readOnly}
          id={id}
          transportCost={transportCost}
          setTransportCost={setTransportCost}
          setTotalDiscount={setTotalDiscount}
          totalDiscount={totalDiscount}
          findTotalAmount={findTotalAmount}
          readOnly={readOnly}
          isTotalDiscount={isTotalDiscount}
          setTransportTax={setTransportTax}
          transportTax={transportTax}
          termsAndCondition={termsAndCondition}
          setTermsAndCondition={setTermsAndCondition}
        />
      </div>
    </>
  );
}

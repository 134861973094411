import React, { useEffect } from 'react'
import { useGetEmployeeQuery, useUpdateAttendanceMutation } from '../../../redux/services/EmployeeMasterService';
import EmployeeCategoryDropdown from '../../ReusableComponents/EmployeeCategoryDropdown';
import { useState } from 'react';
import { DisabledInput } from '../../../Inputs';
import { getDateFromDateTime, getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { TICK_ICON } from '../../../icons';
import { SaveButton } from '../../../Buttons';
import { toast } from 'react-toastify';

const AttendanceForm = ({ selectedDate }) => {
    const [attendanceData, setAttendanceData] = useState([]);
    const [employeeCategoryId, setEmployeeCategoryId] = useState('');
    const { data: employeeData } = useGetEmployeeQuery({ params: { active: true, employeeCategoryId, date: getDateFromDateTime(new Date(selectedDate)), isGetAttendanceDetails: true } }, { skip: !employeeCategoryId });

    const [updateAttendance] = useUpdateAttendanceMutation();

    useEffect(() => {
        if (!employeeData?.data) return
        setAttendanceData(employeeData?.data || []);
    }, [employeeData]);

    function handleChange(index, field, value) {
        setAttendanceData(prev => {
            let newList = structuredClone(prev);
            newList[index][field] = value;
            return newList;
        })
    }

    async function handleSave() {
        const response = await updateAttendance({ date: selectedDate, attendanceDetails: attendanceData }).unwrap();
        if (response?.statusCode === 0) {
            toast.success("Data Saved Successfully")
        } else {
            toast.error(response?.message)
        }
    }

    return (
        <div className='p-2'>
            {/* <div className='text-center text-lg page-heading'>Attendance</div> */}
            <div className='grid grid-cols-5 gap-2'>
                <DisabledInput name={'Date:'} value={getDateFromDateTimeToDisplay(selectedDate)} />
                <EmployeeCategoryDropdown selected={employeeCategoryId} setSelected={setEmployeeCategoryId} />
            </div>
            <div
                className="h-[500px] overflow-auto"
            >
                <table className="table-fixed text-center w-full">
                    <thead className="border-2 table-header">
                        <tr className='h-2'>
                            <th
                                className="border-2  top-0 stick-bg w-10"
                            >
                                S. no.
                            </th>
                            <th
                                className="border-2  top-0 stick-bg "
                            >
                                <div>Reg.No</div>
                            </th>
                            <th
                                className="border-2  top-0 stick-bg"
                            >
                                <div>Emp.Name</div>
                            </th>
                            <th

                                className="border-2  top-0 stick-bg "
                            >
                                <div>Full Day</div>
                            </th>
                            <th

                                className="border-2  top-0 stick-bg "
                            >
                                <div>Half Day</div>
                            </th>
                            <th

                                className="border-2  top-0 stick-bg "
                            >
                                <div>Leave</div>
                            </th>
                            <th

                                className="border-2  top-0 stick-bg "
                            >
                                <div>Permission(Hours)</div>
                            </th>
                            <th

                                className="border-2  top-0 stick-bg "
                            >
                                <div>OT(Hours)</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="border-2">
                        {(attendanceData).map((dataObj, index) => (
                            <tr
                                key={dataObj.id}

                                className="border-2 table-row cursor-pointer"
                            >
                                <td className='py-1'> {(index + 1)}</td>
                                <td className='py-1'> {dataObj.regNo}</td>
                                <td className='py-1'>{dataObj.name}</td>
                                <td className='py-1' onClick={() => {
                                    handleChange(index, 'shiftLength', 'full')
                                }}>
                                    {(dataObj.shiftLength === 'full') && <>{TICK_ICON}</>}
                                </td>
                                <td className='py-1' onClick={() => {
                                    handleChange(index, 'shiftLength', 'half')
                                }}>
                                    {(dataObj.shiftLength === 'half') && <>{TICK_ICON}</>}
                                </td>
                                <td className='py-1' onClick={() => {
                                    handleChange(index, 'shiftLength', 'leave')
                                }}>
                                    {(dataObj.shiftLength === 'leave') && <>{TICK_ICON}</>}
                                </td>
                                <td className='py-1' >
                                    <input type="number" min={0} className='text-right' value={dataObj.permissionHours} onChange={(e) => {
                                        handleChange(index, 'permissionHours', e.target.value)
                                    }} />
                                </td>
                                <td className='py-1' >
                                    <input type="number" min={0} className='text-right' value={dataObj.overTimeHours} onChange={(e) => {
                                        handleChange(index, 'overTimeHours', e.target.value)
                                    }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <SaveButton onClick={handleSave} />
            </div>
        </div>
    )
}

export default AttendanceForm
